import { loginService } from '@/business/loginService'
import { ColvenTheme, ResetPasswordType, SnackbarStyle, NOTIFICATIONS_PAGE_SIZE, ALERTS_PAGE_SIZE } from '@/constants/constants'
import i18n from '@/i18n'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import authApi from '@/api/auth.api'
import userApi from '@/api/user.api'
import enterpriseApi from '@/api/enterprise.api'
import { messagingService } from '@/business/messagingService'
import i18nOriginal from '@/i18nOriginal'
import generalConfigurationApi from '@/api/general-configuration.api'

export default {
  name: 'LoginComponent',
  data () {
    return {
      validForm: false,
      showPassword: false,
      username: '',
      password: '',
      remember: false,
      submitted: false,
      rules: {
        required: value => !!value || `${i18n.t('required')}`
      },
      languages: [
        {
          id: 'es',
          locale: 'es',
          name: i18n.t('login.spanish')
        },
        {
          id: 'us',
          locale: 'en',
          name: i18n.t('login.english')
        },
        {
          id: 'br',
          locale: 'pt',
          name: i18n.t('login.brazilian')
        }
      ],
      selectedLanguage: {
        id: 'es',
        locale: 'es',
        name: i18n.t('login.spanish')
      },
      showSelectEnterprise: false,
      showGoogleAutenticator:false,
      validationCode:null,
      enterprises: [],
      selectedEnterprise: null,
      systemVersion: null,
      loadVersion: false,
      
    }
  },
  async mounted () {
    this.clearSessionData()
    this.setNotifications([])

    this.setAutoReports([])

    i18n.mergeLocaleMessage('es', JSON.parse(i18nOriginal.original).es)
    if (localStorage.locale) {
      this.setLocale(localStorage.locale)
      switch (localStorage.locale) {
        case 'es':
          this.selectedLanguage = this.languages[0]
          break
        case 'en':
          this.selectedLanguage = this.languages[1]
          break
        case 'pt':
          this.selectedLanguage = this.languages[2]
          break
        default:
          this.selectedLanguage = this.languages[0]
          break
      }
    } else {
      // const navigatorLanguage = navigator.language
      this.setLanguage(this.languages[0])
    }
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    if (localStorage.remember) {
      const rememberLocalStorage = localStorage.getItem('remember') === 'true'
      this.setRemember(rememberLocalStorage)
      this.remember = this.getRemember
    }
    if (this.getRemember === true && localStorage.getItem('username')) {
      this.setUsername(localStorage.username)
      this.username = this.getUsername
    }
    if (!this.loadVersion) {
      const config = await generalConfigurationApi.getConfigSystemVersion('portalAgro')
      if (config && config.data && config.data.version) {
        localStorage.setItem('systemVersion', config.data.version)
        this.systemVersion = config.data.version
      }
      this.loadVersion = true
    }
  },
  beforeDestroy () {
    // Carga los temas
    if (this.getThemes) {
      this.$vuetify.theme.themes.light = this.getThemes.light
      this.$vuetify.theme.themes.dark = this.getThemes.dark
    } else {
      this.$vuetify.theme.themes.light = ColvenTheme.light
      this.$vuetify.theme.themes.dark = ColvenTheme.dark
    }
    this.$vuetify.theme.dark = this.getDark
  },
  computed: {
    ...mapGetters('auth', [
      'getUsername',
      'getPassword',
      'getRemember',
      'getLocale',
      'getJwt',
      'getLoginLogo',
      'getLoginBackgroundImage',
      'getThemes'
    ]),
    ...mapGetters({
      'getNotificationsCount': 'app/getNotificationsCount',
      'getNotificationsStore': 'app/getNotifications',
      'getDark': 'app/getDark',
      'getFirebaseToken': 'messaging/getFirebaseToken'
    }),
    /**
     * src del logo
     */
    logoSource () {
      if (this.selectedEnterprise) {
        if (this.selectedEnterprise.logo) {
          return this.selectedEnterprise.logo
        } else {
          return require('../../assets/logo.png')
        }
      } else if (this.getLoginLogo) {
        return 'data:image/png;base64,' + this.getLoginLogo
      } else {
        return require('../../assets/logo.png')
      }
    },
    /**
     * src de la imagen de fondo
     */
    backgroundImageSource () {
      if (this.getLoginBackgroundImage) {
        return 'data:image/png;base64,' + this.getLoginBackgroundImage
      } else {
        return require('../../assets/login-background.jpg')
      }
    }
  },
  methods: {
    ...mapState('app', {
      locale: state => state.locale
    }),
    ...mapMutations('app', [
      'setNotifications',
      'setNotificationsTotalCount',
      'setBadgeCount',
      'setNavBarButtons'
    ]),
    ...mapActions('auth', {
      setId: 'setId',
      setUsername: 'setUsername',
      setPassword: 'setPassword',
      setRemember: 'setRemember',
      setEmail: 'setEmail',
      setJwt: 'setJwt',
      setLogo: 'setLogo',
      setLoginLogo: 'setLoginLogo',
      setLoginBackgroundImage: 'setLoginBackgroundImage',
      setThemes: 'setThemes',
      setLocale: 'setLocale',
      login: 'login',
      setToken: 'setToken',
      setPrivileges: 'setPrivileges',
      setAdmin: 'setAdmin',
      setLocales: 'setLocales',
      setMenu: 'setMenu',
      clearSessionData: 'clearSessionData'
    }),
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar',
      'setAlerts': 'alertNotification/setAlerts',
      'setAlertsTotal': 'alertNotification/setAlertsTotal',
      'setAlertsBadge': 'alertNotification/setBadge',
      'setAutoReports': 'autoReport/setReports'
    }),
    commitChanges () {
      const { username, password, remember } = this
      this.setUsername(username)
      this.setPassword(password)
      this.setRemember(remember)
    },
    setLanguage (lang) {
      this.selectedLanguage = lang
      this.$root.$i18n.locale = lang.locale
      localStorage.setItem('locale', lang.locale)
      this.setLocale(lang.locale)
    },
    forgotPassword () {
      this.$router.push('/reset-password').catch(err => {
        console.log(err)
      })
    },
    async configureAlerts (userSession) {
      const userId = userSession.user._id
      if (userId) {
        /*
          ACTUALIZAR TOKEN DE FIREBASE Y LENGUAJE PARA NOTIFICACIONES EXTERNAS
        */
        userApi.updateAlertConfiguration({ userId, firebaseToken: this.getFirebaseToken, lang: localStorage.locale })
        /*
          NOTIFICACIONES
        */
        // badge
        messagingService.getNotReadCount(userId).then((badgeCount) => {
          this.setBadgeCount({ badgeCount })
        })
        // total
        messagingService.getNotificationsTotalCount(userId).then((totalCount) => {
          this.setNotificationsTotalCount(totalCount)
        })
        // notificaciones
        messagingService.findNotifications(NOTIFICATIONS_PAGE_SIZE, 1, userId).then(response => {
          this.setNotifications(response)
        })
        /*
          ALERTAS
        */
        // badge
        messagingService.getAlertsNotRead(userId).then((badgeCount) => {
          this.setAlertsBadge({ badgeCount })
        })
        // total
        messagingService.getAlertsTotal(userId).then((totalCount) => {
          this.setAlertsTotal(totalCount)
        })
        // alertas
        messagingService.findAlerts(ALERTS_PAGE_SIZE, 1, userId).then(response => {
          this.setAlerts(response)
        })
      }

      this.$router.push('/dashboard')
    },
    async login () {
      this.clearSessionData()
      try {
        let userSession = await loginService.login(this.username, this.password)
        this.setRemember(this.getRemember)
        this.setId(userSession.user.userId)
        this.setUsername(this.username)
        this.setEmail(userSession.user.email)
        this.setJwt(userSession.sessionToken)
        this.setToken(userSession.sessionToken)
        if (userSession.updatePasswordType) {
          authApi.getResetPasswordToken({ username: this.username, password: this.password }).then(tokenResponse => {
            this.setJwt(tokenResponse.data.token)
            switch (userSession.updatePasswordType) {
              case ResetPasswordType.EXPIRATION:
                this.$router.push('/reset-password/expiration/' + tokenResponse.data.token)
                break
              case ResetPasswordType.NEXT_LOGIN:
                this.$router.push('/reset-password/next-login/' + tokenResponse.data.token)
                break
              default:
                this.showSnackbar({ visible: true, text: i18n.t('login.invalidPasswordResetType'), timeout: 6000, style: SnackbarStyle.ERROR })
            }
          })
        }
        //si tiene googleAutenticatorSecret redirige a la ventana de seleccionar empresas para ingresar el token de google
        if(userSession.user.haveGoogleAutenticatorSecret){
          localStorage.setItem('haveGoogleAutenticatorSecret', JSON.stringify(true))
          this.showSelectEnterprise = true;
          this.showGoogleAutenticator = true;
        }
        else{
          localStorage.removeItem('haveGoogleAutenticatorSecret')
          this.showGoogleAutenticator = false;
        }
        if (userSession.enterprise && !this.showSelectEnterprise) {
          await loginService.updateUserData(userSession, this.$i18n, true)
          this.configureAlerts(userSession)
        } else {
          await this.getEnterprises(userSession.enterprises)
          this.showSelectEnterprise = true
        }
      } catch (error) {
        console.log('Error in login action', error)
      }
    },
    async getEnterprises () {
      this.enterprises = (await enterpriseApi.getAllWithLogo()).data
      this.enterprises.forEach(e => {
        e.logo = e.logo ? 'data:image/png;base64,' + e.logo : undefined
      })
      this.selectedEnterprise = this.enterprises[0]
    },
    async submit () {
      if (this.showSelectEnterprise && this.selectedEnterprise) {
        const userSession = await loginService.refreshTokenSecondFactor(this.selectedEnterprise.id,this.validationCode)
       
        if (userSession.enterprise) {
          await loginService.updateUserData(userSession, this.$i18n, true)
          this.configureAlerts(userSession)
        }
      } else {
        await this.login()
      }
    },

  },
  watch: {
  }
}

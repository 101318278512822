<template>
  <div class="full-width full-height">
    <LoginComponent />
  </div>
</template>

<style scoped>
</style>

<script>
// @ is an alias to /src
import LoginComponent from '@/components/auth/LoginComponent.vue'

export default {
  name: 'LoginView',
  components: {
    LoginComponent
  }
}
</script>
